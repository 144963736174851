"use client";

import Link from "next/link";
import { useRouter } from "next/navigation";
import { getIcon } from "@litonarefin/utils/icons/index";

function NotFoundBtn() {
    const router = useRouter();

    return (
        <>
            <button
                onClick={() => router.back()}
                className="jlt-py-4 jlt-px-8 jlt-rounded jlt-bg-white jlt-text-[--primary] jlt-leading-5 jlt-font-bold jlt-shadow-[0px_0px_20px_rgba(173,173,188,0.15)] jlt-flex jlt-items-center jlt-gap-4">
                <span className="jlt-rotate-180">{getIcon("arrowRight")}</span>
                <span>Go Back</span>
            </button>
            <Link
                href="/"
                className="jlt-py-4 jlt-px-8 jlt-rounded jlt-bg-[--primary] jlt-text-white jlt-leading-5 jlt-font-bold">
                Take Me Home
            </Link>
        </>
    );
}

export default NotFoundBtn;
